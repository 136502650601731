/* This file is intended for any pds css integration fixes that conflict with the standard main.css */
div.pds-component-row {
    display: flex;
    flex-flow: row;
    width: 50%;
    gap: 15px;
    padding-inline: 15px;

    &>* {
        flex: 0 1 100%;

        & .pds-input-text.pds-text-widget {
            margin-inline: 0;

            .pds-input-area.pds-text-widget {
                width: 100%;
            }
        }

        & div.pds-select-widget-content.pds-input-select.pds-select-widget {
            margin-inline: 0;

            >div.pds-input-area.pds-select-widget {
                width: 100%;
            }
        }
    }
}

.pds-width-100 {
    width: 100%;

    button.pds-button-multiselect.pds-multi-select-widget {
        width: 100%;
    }

    .pds-text-widget-content.pds-input-text.pds-text-widget {
        margin: 0;
    }
}

@media (min-width: 769px) {
    .pds-width-100 {
        .pds-input-text.pds-text-widget .pds-input-area.pds-text-widget {
            width: 100%;
        }
    }
}


.pds-app-actions {
    .pds-global-search-content.pds-global-search .pds-global-search {
        dd {
            border-left: none;
        }

        dt {
            font-weight: 400;
            font-size: 12px;
        }
    }

    .pds-global-search-content {
        &.pds-global-search {
            margin-right: 15px;
        }

        .pds-global-search-controls,
        h3.pds-global-search-category-title.pds-global-search {
            z-index: 7999;
        }

        .pds-global-search-result-container {
            .pds-global-search-category-title.pds-global-search {
                border-bottom: none;
            }

            li.pds-global-search-results-list-item.pds-global-search {
                padding: 2px 5px;
            }
        }
    }
}

.pds-container {

    h1,
    h2,
    h3,
    h4,
    h5 {
        &.pds-dialog {
            border-bottom: unset;
        }
    }

    button:hover,
    button:focus {

        &:not(.pds-primary, .pds-tertiary) {
            color: #0075db;
        }
    }

    fieldset legend {
        border: none;
        font-weight: normal;
    }

}